import request from "@/utils/request";

// DeliveryOrder
export function deliveryOrderList(params) {
  return request({ url: `/delivery_orders/`, method: "get", params });
}

export function deliveryOrderCreate(data) {
  return request({ url: `/delivery_orders/`, method: "post", data });
}

export function deliveryOrderVoid(data) {
  return request({ url: `/delivery_orders/${data.id}/void/`, method: "post", data });
}

export function deliveryOrderBulkDelete(data) {
  return request({ url: `/delivery_orders/bulk_delete/`, method: "post", data });
}

export function deliveryOrderPrint(data) {
  return request({ url: `/delivery_orders/print_data/`, method: "post", data });
}

export function deliveryRecordVoid(data) {
  return request({ url: `/delivery_records/${data.id}/void/`, method: "post", data });
}

export function pickingRecordVoid(data) {
  return request({ url: `/picking_records/${data.id}/void/`, method: "post", data });
}

// PickingOrder
export function pickingOrderList(params) {
  return request({ url: `/picking_orders/`, method: "get", params });
}

export function pickingOrderPrint(data) {
  return request({ url: `/picking_orders/print_data/`, method: "post", data });
}

// 拣货新增
export function pickingRecordCreate(data) {
  return request({ url: `/picking_records/`, method: "post", data });
}

// 拣货单据新增-货品查询
export function pickingCreateMaterials(params) {
  return request({ url: `/picking_orders/${params.id}/picking_materials/`, method: "get", params });
}

// 拣货记录列表
export function pickingRecordList(params) {
  return request({ url: `/picking_records/`, method: "get", params });
}

export function pickingRecordPrint(data) {
  return request({ url: `/picking_records/print_data/`, method: "post", data });
}
// 拣货单据详情
export function pickingDetail(params) {
  return request({ url: `/picking_orders/${params.id}/`, method: "get", params });
}

// 拣货单据详情-拣货单据货品
export function pickingMaterials(params) {
  return request({ url: `/picking_orders/${params.id}/picking_materials/`, method: "get", params });
}

// 拣货记录详情
export function pickingRecordDetail(params) {
  return request({ url: `/picking_records/${params.id}/`, method: "get", params });
}

// 拣货记录详情-拣货记录货品
export function pickingRecordMaterials(params) {
  return request({ url: `/picking_records/${params.id}/picking_record_materials/`, method: "get", params });
}

// 合并拣货单据
export function pickingMergeCreate(data) {
  return request({ url: `/picking_orders/merge_order/`, method: "post", data });
}

// 完成拣货
export function pickingOrderComplete(data) {
  return request({ url: `/picking_orders/${data.id}/complete/`, method: "post", data });
}

// 完成复核
export function deliveryOrderComplete(data) {
  return request({ url: `/delivery_orders/${data.id}/complete/`, method: "post", data });
}

// 出库通知单列表
export function deliveryTaskList(params) {
  return request({ url: `/delivery_orders/`, method: "get", params });
}

// 出库通知单详情
export function deliveryDetail(params) {
  return request({ url: `/delivery_orders/${params.id}/`, method: "get", params });
}

// 出库通知单货品
export function deliveryMaterials(params) {
  return request({ url: `/delivery_orders/${params.id}/delivery_materials/`, method: "get", params });
}

// 复核记录
export function deliveryRecorList(params) {
  return request({ url: `/delivery_records/`, method: "get", params });
}

// 复核新增
export function deliveryRecordCreate(data) {
  return request({ url: `/delivery_records/`, method: "post", data });
}

// 复核记录详情
export function deliveryRecordDetail(params) {
  return request({ url: `/delivery_records/${params.id}/`, method: "get", params });
}

// 复核记录详情-复核记录货品
export function deliveryRecordMaterials(params) {
  return request({ url: `/delivery_records/${params.id}/delivery_record_materials/`, method: "get", params });
}

export function stockOutReturnRecordCreate(data) {
  return request({ url: `/stock_out_return_records/`, method: "post", data });
}

export function deliveryFileUpload(data) {
  return request({
    url: "/delivery_files/",
    method: "post",
    data,
  });
}

export function quickPicking(data) {
  return request({ url: `/picking_orders/${data.id}/quick_picking/`, method: "post", data });
}

export function printDeliveryPackingOrder(data) {
  return request({ url: `/delivery_orders/print_packing_order/`, method: "post", data });
}
