import request from "@/utils/request";

// Warehouse
export function warehouseList(params) {
  return request({ url: `/warehouses/`, method: "get", params });
}

export function warehouseCreate(data) {
  return request({ url: `/warehouses/`, method: "post", data });
}

export function warehouseUpdate(data) {
  return request({ url: `/warehouses/${data.id}/`, method: "put", data });
}

export function warehouseDestroy(data) {
  return request({ url: `/warehouses/${data.id}/`, method: "delete", data });
}

// ReservoirArea
export function reservoirAreaList(params) {
  return request({ url: `/reservoir_areas/`, method: "get", params });
}

export function reservoirAreaCreate(data) {
  return request({ url: `/reservoir_areas/`, method: "post", data });
}

export function reservoirAreaUpdate(data) {
  return request({ url: `/reservoir_areas/${data.id}/`, method: "put", data });
}

export function reservoirAreaDestroy(data) {
  return request({ url: `/reservoir_areas/${data.id}/`, method: "delete", data });
}

// Location
export function locationList(params) {
  return request({ url: `/locations/`, method: "get", params });
}

export function locationCreate(data) {
  return request({ url: `/locations/`, method: "post", data });
}

export function locationUpdate(data) {
  return request({ url: `/locations/${data.id}/`, method: "put", data });
}

export function locationDestroy(data) {
  return request({ url: `/locations/${data.id}/`, method: "delete", data });
}

// Client
export function clientList(params) {
  return request({ url: `/clients/`, method: "get", params });
}

export function clientCreate(data) {
  return request({ url: `/clients/`, method: "post", data });
}

export function clientUpdate(data) {
  return request({ url: `/clients/${data.id}/`, method: "put", data });
}

export function clientDestroy(data) {
  return request({ url: `/clients/${data.id}/`, method: "delete", data });
}

export function clientResetPassword(data) {
  return request({ url: `/clients/${data.id}/reset_password/`, method: "post", data });
}

// Counterparty
export function counterpartyList(params) {
  return request({ url: `/counterparties/`, method: "get", params });
}

export function counterpartyCreate(data) {
  return request({ url: `/counterparties/`, method: "post", data });
}

export function counterpartyUpdate(data) {
  return request({ url: `/counterparties/${data.id}/`, method: "put", data });
}

export function counterpartyDestroy(data) {
  return request({ url: `/counterparties/${data.id}/`, method: "delete", data });
}

// Supplier
export function supplierList(params) {
  return request({ url: `/suppliers/`, method: "get", params });
}

export function supplierCreate(data) {
  return request({ url: `/suppliers/`, method: "post", data });
}

export function supplierUpdate(data) {
  return request({ url: `/suppliers/${data.id}/`, method: "put", data });
}

export function supplierDestroy(data) {
  return request({ url: `/suppliers/${data.id}/`, method: "delete", data });
}

// Unit
export function unitList(params) {
  return request({ url: `/units/`, method: "get", params });
}

export function unitCreate(data) {
  return request({ url: `/units/`, method: "post", data });
}

export function unitUpdate(data) {
  return request({ url: `/units/${data.id}/`, method: "put", data });
}

export function unitDestroy(data) {
  return request({ url: `/units/${data.id}/`, method: "delete", data });
}

// OrderType
export function orderTypeList(params) {
  return request({ url: `/order_types/`, method: "get", params });
}

export function orderTypeCreate(data) {
  return request({ url: `/order_types/`, method: "post", data });
}

export function orderTypeUpdate(data) {
  return request({ url: `/order_types/${data.id}/`, method: "put", data });
}

export function orderTypeDestroy(data) {
  return request({ url: `/order_types/${data.id}/`, method: "delete", data });
}

// 首页概览
export function homeOverview(params) {
  return request({ url: `/statistics/overview/`, method: "get", params });
}

// 入库趋势
export function homeStockInTrend(params) {
  return request({ url: `/statistics/stock_in_trend/`, method: "get", params });
}

// 出库趋势
export function homeStockOutTrend(params) {
  return request({ url: `/statistics/stock_out_trend/`, method: "get", params });
}

// 库存趋势
export function homeStockTrend(params) {
  return request({ url: `/statistics/inventory_trend/`, method: "get", params });
}

// 平面图
export function planDatas(params) {
  return request({ url: `/statistics/floor_plan/`, method: "get", params });
}

// 调拨单
export function getTransferNumber(params) {
  return request({ url: `/stock_transfer_orders/number/`, method: "get", params });
}

// 出库通知单
export function getDeliveryNumber(params) {
  return request({ url: `/delivery_orders/number/`, method: "get", params });
}

// 入库通知单
export function getReceiptNumber(params) {
  return request({ url: `/receipt_orders/number/`, method: "get", params });
}

// 盘点单
export function getCheckNumber(params) {
  return request({ url: `/stock_check_orders/number/`, method: "get", params });
}

// 货主
export function getClientNumber(params) {
  return request({ url: `/clients/number/`, method: "get", params });
}

export function getCounterpartyNumber(params) {
  return request({ url: `/counterparties/number/`, method: "get", params });
}

// 仓库
export function getWarehousesNumber(params) {
  return request({ url: `/warehouses/number/`, method: "get", params });
}

// 库区
export function getReservoirAreasNumber(params) {
  return request({ url: `/reservoir_areas/number/`, method: "get", params });
}

// 库位
export function getLocationsNumber(params) {
  return request({ url: `/locations/number/`, method: "get", params });
}

// 货品
export function getMaterialsNumber(params) {
  return request({ url: `/materials/number/`, method: "get", params });
}
